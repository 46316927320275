/*global $,console */
jQuery(document).ready(function($){
    'use strict';
    function a(){
        $(window).scroll(function(){
            if( $(this).scrollTop() > 0 ) {
                $('.top-header').css({'padding':'10px 4%'});
                $('#navigation > ul > li > a').css({'padding':'20px 0'});
                $('.main-menu').css('background-color', 'rgba(0,0,0,0.8)');
            } else {
                $('.top-header').css({'padding':'30px 4%'});
                $('#navigation > ul > li > a').css({'padding':'24px 0'});
                $('.main-menu').css('background-color', 'rgba(0,0,0,0.35)');
            }
        });
    }
    a();

    function b() {
        $('.mobile-btn').click(function(){
            $(this).toggleClass('active');

            if( $(this).hasClass('active') ) {
                $('#mobile-navigation').css({'left':0});
            } else {
                $('#mobile-navigation').css({'left':'-100%'});
            }
        });
    }
    b();

});